/** @define contact__form; weak */
.contact__form {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
@media only screen and (min-width: 500px) {
}
.contact__form input,
.contact__form textarea {
  width: 100%;
}
.contact__form input {
  height: 46px;
  padding: 8px 20px 8px 46px;
}
.contact__form input[type="submit"] {
  width: auto;
  height: auto;
  padding: 8px 40px;
  color: #ffffff;
  background-color: #f7a810;
  transition: background-color 0.4s ease-in-out;
}
.contact__form input[type="submit"]:hover {
  background-color: #70aa40;
}
.contact__form textarea {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  padding: 18px 20px 8px 46px;
  resize: none;
}
.contact__form .wrapper {
  width: 100%;
}
@media only screen and (max-width: 1099px) {
  .contact__form .wrapper.submit {
    margin: 15px 0;
  }
}
@media only screen and (min-width: 800px) {
  .contact__form .wrapper.data {
    width: 31%;
  }
}
@media only screen and (min-width: 1200px) {
  .contact__form .wrapper.data {
    width: 48%;
  }
  .contact__form .wrapper.name {
    width: 100%;
  }
}
.contact__form .title {
  margin-bottom: 4px;
  color: #ffffff;
}
@media only screen and (min-width: 1100px) {
  .contact__form .title.message {
    padding-top: 10px;
  }
}
.contact__form .input {
  margin-bottom: 15px;
}
.contact__form .icon_wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
}
.contact__form .icon_wrapper.message {
  height: 70px;
}
.contact__form .icon_svg {
  color: #949cb3;
}
.contact__form .icon_name {
  width: 17px;
  height: 23px;
}
.contact__form .icon_mail {
  width: 20px;
  height: 20px;
}
.contact__form .icon_phone {
  width: 14px;
  height: 24px;
}
.contact__form .icon_chat {
  width: 27px;
  height: 25px;
}
/*# sourceMappingURL=css/contact-form.css.map */